import type { ReactNode } from 'react';

import { Typography } from '@sravni/react-design-system';

import styles from './styles.module.scss';

type Props = {
    title: string;
    children: ReactNode;
};

const { Heading, Text } = Typography;

export const ContentItem = ({ title, children }: Props) => (
    <div className={styles.root}>
        <Text className={styles.title} size={12}>
            {title}
        </Text>
        <Heading className={styles.childrenBlock} level={6} as="div">
            {children}
        </Heading>
    </div>
);
