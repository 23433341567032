import cn from 'classnames';
import { useRouter } from 'next/router';
import type { MouseEventHandler } from 'react';
import React, { memo, useCallback } from 'react';

import { Button } from '@sravni/react-design-system';
import type { ButtonProps } from '@sravni/react-design-system/dist/types/components/Button';

import styles from '@src/components/AdvertisingButton/styles.module.scss';
import { sendOpenDetailsCardEvent } from '@src/helpers/analyticsEvents';

interface IProps extends ButtonProps {
    className?: string;
    link?: string;
    buttonEventAction?: string;
    onClick?: () => void;
    buttonEventLabel: string;
}

export const RedirectButton = memo(({ className, link, onClick, buttonEventLabel, ...buttonProps }: IProps) => {
    const router = useRouter();

    const handleClick = useCallback<MouseEventHandler<HTMLAnchorElement>>(
        (event) => {
            event.stopPropagation();
            event.preventDefault();

            onClick?.();

            if (link) {
                sendOpenDetailsCardEvent(buttonEventLabel);
                router.push(link);
            }
        },
        [buttonEventLabel, link, onClick, router],
    );

    return (
        <a className={cn(styles.link, className)} onClick={handleClick}>
            <Button className={styles.button} variant="primary" {...buttonProps}>
                Получить займ
            </Button>
        </a>
    );
});
